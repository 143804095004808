(function() {
    // https://dashboard.emailjs.com/admin/account
    emailjs.init("IP1_NDQtFQHXTMKe_");
})();

const toastTrigger = document.getElementById('liveToastBtn')
const toastMessage = document.getElementById("toast-body");
const toastLiveExample = document.getElementById('liveToast')

window.onload = function() {
    document.getElementById('contact-form').addEventListener('submit', function(event) {
        event.preventDefault();
        // generate a five digit number for the contact_number variable
        this.contact_number.value = Math.random() * 100000 | 0;
        // these IDs from the previous steps

        document.getElementById("doSubmitText").classList.add("hidden")
        document.getElementById("successIcon").classList.remove("hidden")

        emailjs.sendForm('service_4x05zgu', 'template_mn2t0t4', this)
            .then(function(options) {
                console.log('SUCCESS!');

                document.getElementById("doSubmitText").classList.remove("hidden")
                document.getElementById("successIcon").classList.add("hidden")
        

                if (toastTrigger){
                    const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)

                    if(options.status == 200) {
                        toastBootstrap._element.innerText = "Dankeschön! Deine Anfrage wurde Erfolgreich übermittelt."
                    } else {
                        toastBootstrap._element.innerText = "Schade! Deine Anfrage wurde leider nicht Erfolgreich übermittelt."
                    }
                    
                    toastBootstrap.show()

                }


                  
            }, function(error) {
                console.log('FAILED...', error);
                if (toastTrigger) {
                    const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
                    toastBootstrap._element.innerText = "Schade! Deine Anfrage wurde leider nicht Erfolgreich übermittelt."
                    toastBootstrap.show()
                }
            });
    });
}
